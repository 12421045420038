import React from 'react'

import { StyledButton } from './styled'

export const Button = ({ type = '', href, text, version = 'primary', icon, handleClick, tagName = 'a' }) => {
  return (
    <StyledButton as={tagName} to={href} version={version} type={type} onClick={handleClick}>
      {
        icon && (
          <span>
            <img src={icon} alt={text} />
          </span>
        )
      }
      { text }
    </StyledButton>
  )
}
