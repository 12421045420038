import React, { useState, createContext, useEffect } from 'react'
import { InformationStep } from './steps/Information'
import { ConfirmationStep } from './steps/Confirmation'
import { ThankYouStep } from './steps/ThankYou'
import { Breadcrumbs } from './../../components/Breadcrumbs'

import {
  StyledStep
} from './styled'

export const TransferStepsContext = createContext()

export const TransferMoney = ({ toggleModal }) => {
  const [currentStep, setStep] = useState(0);

  const nextStep = () => setStep(currentStep + 1)
  const prevStep = () => setStep(currentStep - 1)
  const resetSteps = () => {
    toggleModal()

    setTimeout(() => {
      setStep(0)
    }, 500)
  }

  useEffect(() => {
    console.log(currentStep)
  }, [currentStep])

  return (
    <TransferStepsContext.Provider value={{ nextStep, prevStep, resetSteps }}>
      <Breadcrumbs 
        options={['Information', 'Confirmation', 'Finish']}
        activeStep={currentStep}
      />
      <StyledStep isActive={0 == currentStep}>
        <InformationStep />
      </StyledStep>
      <StyledStep isActive={1 == currentStep}>
        <ConfirmationStep />
      </StyledStep>
      <StyledStep isActive={2 == currentStep}>
        <ThankYouStep />
      </StyledStep>
    </TransferStepsContext.Provider>
  )
}
