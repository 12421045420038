import React from 'react'
import transferIcon from './../../../images/icons/transfer-bg.svg'
import { YourCards } from './../../YourCards'
import { TransferForm } from './../../TransferForm'

import {
  StyledHead,
  StyledTitle,
  StyledLabel,
  StyledOption,
  StyledSelect,
  StyledContent,
  StyledColumn
} from './styled'

export const InformationStep = () => {
  return (
    <>
      <StyledHead>
        <StyledTitle>
          <img src={transferIcon} alt="" />
          Transfer money
        </StyledTitle>
        <StyledOption>
          <StyledSelect>
            <span>
              Type of transfer:
            </span>
            <select>
              <option>disposable</option>
            </select>
          </StyledSelect>
        </StyledOption>
      </StyledHead>
      <StyledContent>
        <StyledColumn>
          <StyledLabel>
            Your Cards
          </StyledLabel>
          <YourCards />
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>
            Transfer template
          </StyledLabel>
          <TransferForm />
        </StyledColumn>
      </StyledContent>
    </>
  )
}
