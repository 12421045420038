import styled from 'styled-components'
import { LabelTypo, Paragraph2 } from './../../global/typography'

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const StyledCheckboxLabel = styled.h3`
  ${Paragraph2};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.silver };

  span {
    ${LabelTypo};
    color: #1F293B;
    font-weight: 700;
    display: block;
    padding-bottom: 8px;
  }
`

export const StyledToggle = styled.button`
  position: relative;
  width: 42px;
  height: 24px;
  background-color: ${({ isChecked }) => isChecked ? '#47BA67' : '#C8D1DB'};
  border-radius: 20px;
  margin-top: 10px;
  transition: .2s ease-in;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    transform: ${({ isChecked }) => isChecked ? 'translateX(4px)' : 'translateX(22px)'};
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white };
    transition: .2s ease-in;
  }
`