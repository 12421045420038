import styled, { css } from 'styled-components'

export const StyledDropdown = styled.div`
  cursor: pointer;
  position: relative;
`

export const StyledLabel = styled.h3`
  display: flex;
  font-size: 1.6rem;
  color: #64748B;
  font-weight: 600;

`

export const StyledIcon = styled.div`
  margin-left: 15px;
  background-color: #F2F0FA;
  border-radius: 8px;
  padding: 10px;
  display: flex;

  img {
    transform: ${({ isOpened }) => isOpened ? 'rotate(180deg)' : 'rotate(0deg)' }
  }
`

export const StyledOptions = styled.ul`
  position: absolute;
  display: ${({ isOpened }) => isOpened ? 'block' : 'none' };
  top: 120%;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white };
  padding: 5px;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0px 17px 318px rgba(33,33,33,0.04), 0px 7.64243px 71.0295px rgba(33,33,33,0.0238443), 0px 4.65983px 21.1473px rgba(33,33,33,0.0161557);
  width: 100px;
`

export const StyledOption = styled.li`
  font-size: 1.2rem;
  font-weight: 600;
  padding: 6px 5px;
  color: ${({ theme }) => theme.colors.gray };
  transition: .2s ease-in;

  &:hover {
    color: ${({ theme }) => theme.colors.gray2 };
  }
`
