import React from 'react'
import closeIcon from './../../images/icons/close.svg'

import {
  StyledModalOverlay,
  StyledModal,
  StyledModalClose,
  StyledModalContent
} from './styled'

export const Modal = ({ isOpen, toggleModal, children }) => {
  return (
    <StyledModalOverlay isOpen={isOpen}>
      <StyledModal>
        <StyledModalClose onClick={toggleModal}>
          <img src={closeIcon} alt="Close modal" />
        </StyledModalClose>
        <StyledModalContent>
          { children }
        </StyledModalContent>
      </StyledModal>
    </StyledModalOverlay>
  )
}
