import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import editIcon from './../../images/icons/edit.svg'
import calendarIcon from './../../images/icons/calendar.svg'
import { TransferStepsContext } from '../TransferMoney'
import { Label } from './../Label'

import { StyledLabel } from '../TransferMoney/steps/styled'

import {
  StyledForm,
  StyledInline,
  StyledWrapper,
  StyledAction,
  StyledError
} from './styled'

export const TransferForm = () => {
  const { register, handleSubmit, errors } = useForm();
  const { nextStep } = useContext(TransferStepsContext)
  const onSubmit = () => nextStep();

  const [numberField, setNumberField] = useState('');
  const [nameField, setNameField] = useState('');
  const [titleField, setTitleField] = useState('');
  const [amountField, setAmountField] = useState('');

  const fillFields = () => {
    setNumberField('54 6542 8765 0000 6548')
    setNameField('John Doe')
    setTitleField('Order No. 432567')
    setAmountField('$ 2 250.90')
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledInline>
        <StyledWrapper>
          <select name="type">
            <option value="Tremblay LLC">Tremblay LLC</option>
            <option value="Tremblay LLC">Tremblay LLC</option>
            <option value="Tremblay LLC">Tremblay LLC</option>
          </select>
        </StyledWrapper>
        <StyledAction>
          <img src={editIcon} alt="edit" />
        </StyledAction>
      </StyledInline>
      <fieldset>
        <Label labelFor="number" text={'Account number'} />
        <input 
          name="number" 
          ref={register({ required: true })} 
          onChange={(e) => setNumberField(e.currentTarget.value) } 
          onClick={fillFields}
          value={numberField} 
          style={{ boxShadow: errors.number ? 'rgb(175 17 35) 0px 0px 0px 2px' : '' }}
          placeholder={errors.number ? 'Account number is required' : ''}
        />
        <Label labelFor="fullname" text={'Full name'} />
        <input 
          name="fullname" 
          ref={register({ required: true })} 
          onChange={(e) => setNameField(e.currentTarget.value) } 
          onClick={fillFields}
          value={nameField}
          style={{ boxShadow: errors.fullname ? 'rgb(175 17 35) 0px 0px 0px 2px' : '' }}
          placeholder={errors.number ? 'Full name is required' : ''}
        />
      </fieldset>
      <fieldset>
        <StyledLabel>
          Transfer details
        </StyledLabel>
        <Label labelFor="title" text={'Title'} />
        <input 
          name="title" 
          ref={register({ required: true })} 
          onChange={(e) => setTitleField(e.currentTarget.value) } 
          onClick={fillFields}
          value={titleField} 
          style={{ boxShadow: errors.title ? 'rgb(175 17 35) 0px 0px 0px 2px' : '' }}
          placeholder={errors.number ? 'Title is required' : ''}
        />
      </fieldset>
      <StyledInline>
        <StyledWrapper>
          <Label labelFor="amount" text={'Amount'} />
          <input 
            name="amount" 
            ref={register({ required: true })} 
            onChange={(e) => setAmountField(e.currentTarget.value) } 
            onClick={fillFields}
            value={amountField} 
            style={{ boxShadow: errors.amount ? 'rgb(175 17 35) 0px 0px 0px 2px' : '' }}
            placeholder={errors.number ? 'Amount is required' : ''}
          />
        </StyledWrapper>
        <StyledAction>
          <img src={calendarIcon} alt="edit" />
        </StyledAction>
      </StyledInline>
      <input name="submit" type="submit" value="Continue" />
    </StyledForm>
  )
}
