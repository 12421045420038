export const summaryData = [
  {
    name: 'MON', balance: 800,
  },
  {
    name: 'THU', balance: 3080,
  },
  {
    name: 'FRI', balance: 1490,
  },
  {
    name: 'SAT', balance: 3390,
  },
  {
    name: 'SUN', balance: 2490,
  },
];