import styled from 'styled-components'
import { Heading3, LabelTypo, Paragraph6 } from './../../../global/typography'
import expand3 from './../../../images/icons/expand3.svg'

export const StyledHead = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.bp.sm} {
    flex-direction: row;
  }
`

export const StyledTitle = styled.h2`
  ${Heading3};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray3 };
  width: 100%;

  @media ${({ theme }) => theme.bp.sm} {
    width: 60%;
  }

  img {
    padding-right: 15px;
  }
`

export const StyledOption = styled.div`
  width: 40%;
  display: flex;
  margin-top: 25px;

  @media ${({ theme }) => theme.bp.sm} {
    margin-top: 0;
  }

  span,
  select {
    ${LabelTypo};
    color: #1F293B;
    appearance: none;
  }

  select {
    color: ${({ theme }) => theme.colors.gray5 };
    border: 0;
    background-color: ${({ theme }) => theme.colors.lightcreme };
    padding: 5px;
    padding-right: 30px;
    border-radius: ${({ theme }) => theme.radius.small };
    background-image: url(${expand3});
    background-repeat: no-repeat;
    background-position: 95% 50%;

    @media ${({ theme }) => theme.bp.sm } {
      margin-left: 10px;
    }
  }
`

export const StyledSelect = styled.div`
  width: 100%;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  @media ${({ theme }) => theme.bp.md } {
    flex-direction: row;
  }
`

export const StyledColumn = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.bp.sm} {
    width: 40%;

    &:first-of-type {
      width: 60%;
    }
  }
`
export const StyledLabel = styled.h3`
  ${LabelTypo};
  color: #1F293B;
  padding-bottom: 20px;
`

export const StyledSection = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.bp.sm } {
    flex-direction: row;
  }
`

export const StyledEqualColumn = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  a {
    margin-top: 0;
  }

  @media ${({ theme }) => theme.bp.sm } {
    width: 50%;
  }  
`

export const StyledHeading = styled.h2`
  ${Heading3};
  color: ${({ theme }) => theme.colors.gray3 };
  display: flex;

  img {
    margin-left: 15px;
  }
`

export const StyledDescription = styled.p`
  ${Paragraph6};
  color: ${({ theme }) => theme.colors.silver };
  margin: 25px 0;
`