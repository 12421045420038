import React, { useState, useContext } from 'react'
import lockIcon from './../../images/icons/lock.svg'
import successIcon from './../../images/icons/success.svg'
import { Button } from './../../components/Button'
import { Checkbox } from './../../components/Checkbox'

import { TransferStepsContext } from '../TransferMoney'


import {
  StyledVerification,
  StyledTitle,
  StyledCode,
  StyledDescription,
  StyledLabel,
  StyledRequirements,
} from './styled'

export const VerificationCode = () => {
  const [success, setSuccess] = useState(false)
  const [values, setValues] = useState([])
  const { nextStep } = useContext(TransferStepsContext)

  const validateCode = () => {
    setValues(['2','3','4','5','6'])
    setSuccess(true)
  }

  return (
    <>
      <StyledVerification>
        <StyledTitle>
          Verification code
          <img src={lockIcon} alt="" />
        </StyledTitle>
        <StyledDescription>
          We have sent a verification code to your phone number. Please enter code below.
        </StyledDescription>
        <StyledCode onClick={validateCode}>
          <input name="1" type="text" maxLength={1} value={values[0]} />
          <input name="2" type="text" maxLength={1} value={values[1]} />
          <input name="3" type="text" maxLength={1} value={values[2]} />
          <input name="4" type="text" maxLength={1} value={values[3]} />
          <input name="5" type="text" maxLength={1} value={values[4]} />
        </StyledCode>
        {
          !success ? (
            <StyledLabel>
              I didn't receive a code. <a href="#">Resend</a>
            </StyledLabel>
          ) : (
            <StyledLabel>
              Successful verification!
              <img src={successIcon} alt="" />
            </StyledLabel>
          )
        }
      </StyledVerification>
      <StyledRequirements>
        <Checkbox
          label="send a confirmation"
          text="emie****gmail.com"
        />
        <Checkbox
          label="Tremblay LLC"
          text="emie****gmail.com"
        />
      </StyledRequirements>
      <Button 
        href="#"
        text="Please enter code"
        version={success ? "submit" : "freeze"}
        handleClick={nextStep}
      />
    </>
  )
}
