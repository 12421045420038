import React, { useState } from 'react'
import { Input } from './../Input'
import editIcon from './../../images/icons/edit.svg'
import closeIcon from './../../images/icons/close.svg'

import {
  StyledDetails,
  StyledTitle,
  StyledData,
  StyledInline,
  StyledToggle
} from './styled'

export const SenderDetails = () => {
  const [isEdit, setEdit] = useState(false);
  const [accountNumber, setAccountNumber] = useState('44 2545 0000 6546 8943')
  const [recieverNumber, setRecieverNumber] = useState('22 2346 7777 6546 8943')
  const [fullName, setFullName] = useState('Emie Rempel')
  const [recieverFullName, setRecieverFullName] = useState('Emie Rempel')
  const [transferDetails, setTransferDetails] = useState('Order No. 432567')

  const handleToggle = () => {
    setEdit(!isEdit)
  }
  
  return (
    <StyledDetails>
      <StyledToggle onClick={handleToggle}>
        <img src={isEdit ? closeIcon : editIcon} alt="edit" />
      </StyledToggle>
      <StyledTitle>
        Sender details
      </StyledTitle>
      <StyledData>
        <span>
          Account number
        </span>
        {
          isEdit ? (
            <Input
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.currentTarget.value)}
            />
          ) : (
            accountNumber
          )
        }
      </StyledData>
      <StyledInline>
        <StyledData>
          <span>
            Full name
          </span>
          {
            isEdit ? (
              <Input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.currentTarget.value)}
              />
            ) : (
              fullName
            )
          }
        </StyledData>
        <StyledData>
          <span>
            Card balance
          </span>
          $5 346.78
        </StyledData>
      </StyledInline>
      <StyledTitle>
        Reciever details
      </StyledTitle>
      <StyledData>
        <span>
          Account number
        </span>
        {
          isEdit ? (
            <Input
              type="text"
              value={recieverNumber}
              onChange={(e) => setRecieverNumber(e.currentTarget.value)}
            />
          ) : (
            recieverNumber
          )
        }
      </StyledData>
      <StyledInline>
        <StyledData>
          <span>
            Full name
          </span>
          {
            isEdit ? (
              <Input
                type="text"
                value={recieverFullName}
                onChange={(e) => setRecieverFullName(e.currentTarget.value)}
              />
            ) : (
              recieverFullName
            )
          }
        </StyledData>
        <StyledData>
          <span>
            Card balance
          </span>
          $5 346.78
        </StyledData>
      </StyledInline>
      <StyledTitle>
        Transfer details
      </StyledTitle>
      <StyledData>
        <span>
          Title
        </span>
          {
            isEdit ? (
              <Input
                type="text"
                value={transferDetails}
                onChange={(e) => setTransferDetails(e.currentTarget.value)}
              />
            ) : (
              transferDetails
            )
          }
      </StyledData>
      <StyledInline>
        <StyledData>
          <span>
            Account
          </span>
          $2 250.90
        </StyledData>
        <StyledData>
          <span>
            date
          </span>
          10/22/2020
        </StyledData>
      </StyledInline>
    </StyledDetails>
  )
}
