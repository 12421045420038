import React, { useState } from 'react'
import { Button } from './../Button'
import { cardsData } from './../../content/cards'
import { PickCardSingle } from './single'
import transferIcon from './../../images/icons/transfer-black.svg'

import {
  StyledList,
} from './styled'

export const PickCard = ({ toggleModal }) => {
  const [activeCard, setActiveCard] = useState(0)
  
  return (
    <>
      <StyledList>
        {
          cardsData.length && (
            cardsData.map((item, index) => (
              <PickCardSingle
                key={index}
                image={item.image}
                balance={item.balance}
                number={item.number}
                isActive={index === activeCard}
                handleClick={() => setActiveCard(index)}
              />
            ))
          )
        }
      </StyledList>
      <Button 
        version="more"
        href="#"
        text="Transfer money"
        icon={transferIcon}
        handleClick={toggleModal}
      />
    </>
  )
}
