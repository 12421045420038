import React from 'react'
import cardIcon from './../../images/icons/card.svg'

import styled from 'styled-components'
import { Heading6, Paragraph2 } from './../../global/typography'

export const StyledWelcome = styled.section`
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${({ theme }) => theme.bp.md } {
    display: none;
  }
`

export const StyledTitle = styled.h2`
  ${Heading6};

  span {
    padding-bottom: 5px;
    ${Paragraph2};
    display: block;
    color: ${({ theme }) => theme.colors.gray2 };
  }
`

export const StyledButton = styled.button`
  border-radius: ${({ theme }) => theme.radius.medium };
  padding: 14px;
  background-color: ${({ theme }) => theme.colors.gray6 };
`

export const WelcomeUser = () => {
  return (
    <StyledWelcome>
      <StyledTitle>
        <span>
          Good morning
        </span>
        Emie Rempel
      </StyledTitle>
      <StyledButton>
        <img src={cardIcon} alt="Card" />
      </StyledButton>
    </StyledWelcome>
  )
}
