import styled, { css } from 'styled-components'
import cardMask from './../../images/card-mask.png'
import { LabelTypo, Paragraph2, Heading4 } from './../../global/typography'

export const StyledSelected = styled.div`
  position: absolute;
  top: -35px;
  right: -35px;
  border-radius: ${({ theme }) => theme.radius.round };
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.darkBorder };
  display: none;
  place-content: center;
`

export const StyledCard = styled.article`
  position: relative;
  background: ${({ cardBackground }) => cardBackground};
  max-width: 350px;
  border-radius: ${({ theme }) => theme.radius.medium };
  padding: 25px;
  cursor: pointer;

  ${({ isSelected }) => isSelected && css`
    box-shadow: inset 0px 0px 0px 5px ${({ theme }) => theme.colors.darkBorder };

    &:before {
      position: absolute;
    }

    ${StyledSelected} {
      display: flex;
    }
  `}

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: url('${cardMask}');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: ${({ theme }) => theme.radius.medium };
  }
`

export const StyledCardContent = styled.div`
  position: relative;
  z-index: 1;
`

export const StyledCardBalance = styled.h3`
  color: ${({ theme }) => theme.colors.white };
  ${Heading4};

  span {
    display: block;
    ${LabelTypo};
  }
`
export const StyledCardNumber = styled.h3`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white };
  letter-spacing: 0.2rem;
  padding-top: 10px;
`
export const StyledCardExpires = styled.h3`
  color: ${({ theme }) => theme.colors.white };
  ${Paragraph2};
  padding-top: 17px;
`