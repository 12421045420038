import React from 'react'
import { Dropdown } from './../Dropdown'
import { SlideInAnimation } from './../SlideInAnimation';

import {
  StyledBox,
  StyledData,
  StyledHead,
  StyledTitle,
  StyledMain
} from './styled'

export const DataSection = ({ title, action, children, desktopWidth, icon, delay }) => {
  return (
    <StyledBox desktopW={desktopWidth}>
      <SlideInAnimation delay={delay}>
        <StyledData>
          <StyledHead>
            <StyledTitle>
              <img src={icon} alt={title} />
              {title}
            </StyledTitle>
            <Dropdown 
              label={'Today'}
              options={[
                'Today',
                'Yesterday',
                'Tomorrow'
              ]}
            />
          </StyledHead>
          <StyledMain>
            { children }
          </StyledMain>
        </StyledData>
      </SlideInAnimation>
    </StyledBox>
  )
}
