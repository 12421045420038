import styled from 'styled-components'

export const StyledModalOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.modalOverlay };
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
  pointer-events: ${({ isOpen }) => isOpen ? 'auto' : 'none'};
  transition: .25s ease-in;
`

export const StyledModal = styled.div`
  position: relative;
  width: 100%;
  max-width: 915px;
  background-color: ${({ theme }) => theme.colors.white };
  padding: 20px 40px;
  border-radius: 16px;
  max-height: 85vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${({ theme }) => theme.bp.md } {
    padding: 40px 60px 0 60px;
  }
`

export const StyledModalClose = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #F9FAFB;
  border-radius: 8px;
  height: 32px;

  @media ${({ theme }) => theme.bp.md } {
    right: 32px;
    top: 28px;
  }

  img {
    padding: 10px;
  }
`

export const StyledModalContent = styled.div``