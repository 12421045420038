import React from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { summaryData } from './../../content/graph'

import { StyledGraph } from './styled'

export const SummaryGraph = () => {
  return (
    <StyledGraph>
      <ResponsiveContainer width={'100%'} height={250} debounce={1}>
        <LineChart
          width={500}
          height={300}
          data={summaryData}
          margin={{
            top: 10, right: 10, left: -25,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip 
            itemStyle={{ background: '#3C3A60', borderRadius: '8px', fontWeight: '600', color: '#fff', padding: '10px' }}
            labelStyle={{ display: 'none' }}
            separator={' '}
            label={''}
            formatter={(value) => `$${value}`}
          />
          <Line 
            type="monotone" 
            dataKey="balance" 
            stroke="#4B53B3" 
            strokeWidth={3} 
            activeDot={{ stroke: '#46476D', strokeWidth: 2, border: '2px solid white' }} 
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledGraph>
  )
}
