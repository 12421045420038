
import styled from 'styled-components'
import { Paragraph3, Paragraph4 } from './../../global/typography'

export const StyledActivity = styled.div`
  flex: 50%;
  order: 1;
  justify-content: flex-start;

  @media ${({ theme }) => theme.bp.md } {
    flex: 40%;
    order: 0;
  }
`

export const StyledCategory = styled.div`
  flex: 50%;
  display: flex;
  order: 1;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.gray };

  @media ${({ theme }) => theme.bp.md } {
    flex: 20%;
    justify-content: flex-start;
    order: 0;
  }
`

export const StyledTime = styled.div`
  flex: 50%;
  padding-bottom: 10px;
  justify-content: flex-end;
  text-align: right;

  @media ${({ theme }) => theme.bp.md } {
    flex: 20%;
    padding-bottom: 0;
  }
`

export const StyledAmount = styled.div`
  flex: 50%;
  font-weight: 600;
  padding-bottom: 10px;
  display: flex;
  order: -1;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.gray3 };
  ${Paragraph4};

  @media ${({ theme }) => theme.bp.md } {
    flex: 20%;
    padding-bottom: 0;
    order: 0;
    justify-content: flex-end;
  }
`

export const StyledLabels = styled.div`
  display: none;

  @media ${({ theme }) => theme.bp.md } {
    display: flex;
    padding: 0 20px 4px 20px;
  }

  ${StyledActivity},
  ${StyledCategory},
  ${StyledTime},
  ${StyledAmount} {
    ${Paragraph3};
    color: ${({ theme }) => theme.colors.gray5 };
  }
`

export const StyledMain = styled.div`
  ${StyledActivity},
  ${StyledCategory},
  ${StyledTime} {
    ${Paragraph4};
    color: ${({ theme }) => theme.colors.gray2 };
  }
`

export const StyledRow = styled.div`
  display: flex;
  padding: 15px 0px;
  transition: ${({ theme }) => theme.transitions.fast };
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(185, 193, 217, .2);
  margin-bottom: 5px;

  &:last-of-type {
    border: 0; 
    margin: 0;
  }

  @media ${({ theme }) => theme.bp.md } {
    padding: 16px 20px;
    border: 0;
    border-radius: ${({ theme }) => theme.radius.xlarge };

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray7 };

      ${StyledActivity},
      ${StyledCategory},
      ${StyledTime},
      ${StyledAmount} {
        color: ${({ theme }) => theme.colors.gray3 };
      }
    }
  }
`