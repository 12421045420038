import styled, { css } from 'styled-components'
import { ButtonTypo } from './../../global/typography'
import { totalCenter } from './../../global/mixins'

export const StyledButton = styled.button`
  ${ButtonTypo};
  ${totalCenter};
  border-radius: ${({ theme }) => theme.radius.medium };
  padding: 16px;
  margin-top: 15px;
  transition: ${({ theme }) => theme.transitions.fast };

  ${({ version }) => version === 'more' && css`
    background-color: #F8FAFB;
    color: ${({ theme }) => theme.colors.gray3 };
  `}

  ${({ version }) => version === 'submit' && css`
    background-color: ${({ theme }) => theme.colors.darkBorder };;
    color: ${({ theme }) => theme.colors.white };
  `}

  ${({ version }) => version === 'freeze' && css`
    background-color: ${({ theme }) => theme.colors.darkBorder };
    color: ${({ theme }) => theme.colors.white };
    opacity: 0.3;
    cursor: not-allowed;
  `}

  ${({ version }) => version === 'primary' && css`
    background-color: ${({ theme }) => theme.colors.darkBorder };
    color: ${({ theme }) => theme.colors.white };
  `}

  img {
    padding-right: 10px;
  }
`