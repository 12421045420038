import React from 'react'

import {
  StyledRow,
  StyledCategory,
  StyledProgressValue,
  StyledProgress,
  StyledValue
} from './../styled'

export const ChargesStatsSingle = ({ category, progress }) => {
  return (
    <StyledRow>
      <StyledCategory>
        { category }
      </StyledCategory>
      <StyledProgress>
        <StyledProgressValue style={{ 'transform': `translateX(-${100 - progress}%)` }} />
      </StyledProgress>
      <StyledValue>
        {progress}%
      </StyledValue>
    </StyledRow>
  )
}
