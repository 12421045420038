import React, { useContext } from 'react'
import successIcon from './../../../images/icons/success.svg'
import terminal from './../../../images/terminal.png'
import { Button } from './../../Button'
import { TransferStepsContext } from '../../TransferMoney'

import {
  StyledSection,
  StyledEqualColumn,
  StyledHeading,
  StyledDescription,
} from './styled'

export const ThankYouStep = () => {
  const { resetSteps } = useContext(TransferStepsContext)
  
  return (
    <StyledSection>
      <StyledEqualColumn>
        <StyledHeading>
          Transfer completed!
          <img src={successIcon} alt="" />
        </StyledHeading>
        <StyledDescription>
          Your transfer <strong>$2 250.90</strong> has been processed successfully!
        </StyledDescription>
        <Button 
          text="Back to dashboard"
          url="/"
          version="primary"
          handleClick={resetSteps}
        />
        <StyledDescription>
          Transaction confirmation in the <strong>PDF version</strong>
        </StyledDescription>
      </StyledEqualColumn>
      <StyledEqualColumn>
        <img src={terminal} alt="" />
      </StyledEqualColumn>
    </StyledSection>
  )
}
