import React, { useState } from 'react'
import { Card } from './../Card'
import { yourCardsData } from './../../content/your-cards'

import { StyledCards } from './styled'

export const YourCards = () => {
  const [selectedCard, setCard] = useState(0)

  const handleSelectCard = id => {
    setCard(id)
  }

  return (
    <StyledCards>
      {
        yourCardsData.length && (
          yourCardsData.map((item, index) => (
            <Card 
              key={index}
              balance={item.balance}
              number={item.number}
              expires={item.expires}
              color={item.color}
              isSelected={index === selectedCard}
              handleClick={() => handleSelectCard(index)}
            />
          ))
        )
      }
    </StyledCards>
  )
}
