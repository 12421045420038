import React from 'react'
import selectedIcon from './../../images/icons/selected.svg'

import {
  StyledCard,
  StyledCardContent,
  StyledCardBalance,
  StyledCardNumber,
  StyledCardExpires,
  StyledSelected
} from './styled'

export const Card = ({ balance, number, expires, color, isSelected, handleClick }) => {
  return (
    <StyledCard cardBackground={color} onClick={handleClick} isSelected={isSelected}>
      <StyledCardContent>
        <StyledCardBalance>
          <span>card balance</span>
          ${balance}
        </StyledCardBalance>
        <StyledCardNumber>
          {number}
        </StyledCardNumber>
        <StyledCardExpires>
          {expires}
        </StyledCardExpires>
        <StyledSelected>
          <img src={selectedIcon} alt="Selected card" />
        </StyledSelected>
      </StyledCardContent>
    </StyledCard>
  )
}
