import styled from 'styled-components'
import expandIcon from './../../images/icons/expand.svg'
import { LabelTypo } from './../../global/typography'

export const StyledBreadcrumbs = styled.ul`
  display: flex;
  margin-left: 5px;

  @media ${({ theme }) => theme.bp.sm} {
    margin-left: -15px;
    margin-top: 0;
  }
`

export const StyledBreadcrumb = styled.li`
  position: relative;
  padding: 0 15px 0 10px;
  opacity: ${({ isActive }) => isActive ? '1' : '.5' };

  @media ${({ theme }) => theme.bp.sm} {
    padding: 0 25px 0 20px;
  }

  &:before {
    position: absolute;
    content: '';
    left: -5px;
    top: 10px;
    transform: rotate(-90deg);
    width: 8px;
    height: 10px;
    background-size: 8px 10px;
    background-position: center;
    background-image: url(${expandIcon});
    background-repeat: no-repeat;
  }
`

export const StyledLink = styled.a`
  ${LabelTypo};
  color: ${({ theme }) => theme.colors.gray3 };
`