import React, { useState } from 'react'
import expand from './../../images/icons/expand2.svg'

import {
  StyledDropdown,
  StyledLabel,
  StyledIcon,
  StyledOptions,
  StyledOption
} from './styled'

export const Dropdown = ({ label, handleChange, options }) => {
  const [dropdownOpened, toggleDropdownOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(label);

  const handleSelectOption = (item) => {
    setSelectedOption(item)
    toggleDropdownOpened(!dropdownOpened)
  }

  return (
    <StyledDropdown>
      <StyledLabel onClick={() => toggleDropdownOpened(!dropdownOpened)}>
        { selectedOption }
        <StyledIcon isOpened={dropdownOpened}>
          <img src={expand} alt="Open dropdown" />
        </StyledIcon>
      </StyledLabel>
      <StyledOptions isOpened={dropdownOpened}>
        {
          options.length && (
            options.map((item, index) => (
              <StyledOption key={index} onClick={() => handleSelectOption(item)}>
                {item}
              </StyledOption>
            ))
          )
        }
      </StyledOptions>
    </StyledDropdown>
  )
}
