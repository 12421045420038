import React from 'react'
import { Button } from './../Button'
import { chargesData } from './../../content/charges'
import { ChargesStatsSingle } from './Single'

import {
  StyledLabels,
  StyledLabel,
} from './styled'

export const ChargesStats = () => {
  return (
    <>
      <StyledLabels>
        <StyledLabel>
          Category
        </StyledLabel>
        <StyledLabel>
          Value
        </StyledLabel>
      </StyledLabels>
      <>
        {
          chargesData.length && (
            chargesData.map((item, index) => (
              <ChargesStatsSingle 
                key={index}
                {...item}
              />
            ))
          )
        }
      </>
      <Button 
        version="more"
        href="#"
        text="Show more"
      />
    </>
  )
}
