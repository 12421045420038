import React from 'react'
import { transactionsData } from './../../content/transactions'
import { Button } from './../Button'
import { TransactionHistorySingle } from './Single'

import {
  StyledLabels,
  StyledActivity,
  StyledCategory,
  StyledTime,
  StyledAmount,
  StyledMain, 
} from './styled'

export const TransactionHistory = () => {
  return (
    <>
      <StyledLabels>
        <StyledActivity>
          Activity
        </StyledActivity>
        <StyledCategory>
          Category
        </StyledCategory>
        <StyledTime>
          Time
        </StyledTime>
        <StyledAmount>
          Amount
        </StyledAmount>
      </StyledLabels>
      <StyledMain>
        {
          transactionsData.length ? (
            transactionsData.map((item, index) => (
              <TransactionHistorySingle
                key={index}
                {...item}
              />
            ))
          ) : (
            'Transaction history is empty'
          )
        }
      </StyledMain>
        <Button 
          version="more"
          href="#"
          text="Show more"
        />
    </>
  )
}
