import styled from 'styled-components'

export const StyledForm = styled.form`
  fieldset:last-of-type {
    margin-top: 65px;

    h3 {
      padding-bottom: 0;
    }
  }

  input::placeholder {
    color: rgb(175 17 35);
  }
`

export const StyledInline = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`
export const StyledAction = styled.button`
  background-color: ${({ theme }) => theme.colors.lightcreme };
  padding: 16px;
  border-radius: 14px;
  height: 48px;
  display: flex;
  place-content: center;
  margin-left: 15px;
`

export const StyledWrapper = styled.div`
  width: 100%;
`

export const StyledError = styled.span`
  font-size: 1rem;
  margin-top: 5px;
  color: red;
  font-weight: 600;
`