import React from 'react'
import transferIcon from './../../../images/icons/transfer-bg.svg'
import { SenderDetails } from './../../SenderDetails'
import { VerificationCode } from './../../VerificationCode'

import {
  StyledHead,
  StyledTitle,
  StyledLabel,
  StyledContent,
  StyledColumn
} from './styled'

export const ConfirmationStep = () => {
  return (
    <>
      <StyledHead>
        <StyledTitle>
          <img src={transferIcon} alt="" />
          Transfer money
        </StyledTitle>
      </StyledHead>
      <StyledContent>
        <StyledColumn>
          <StyledLabel>
            Summary
          </StyledLabel>
          <SenderDetails />
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>
            Confirmation details
          </StyledLabel>
          <VerificationCode />
        </StyledColumn>
      </StyledContent>
    </>
  )
}
