
import styled from 'styled-components'
import { Paragraph3, Paragraph4, Paragraph5 } from './../../global/typography'

export const StyledLabels = styled.div`
  display: none;

  @media ${({ theme }) => theme.bp.md } {
    display: flex;
    justify-content: space-between;
  }
`

export const StyledValue = styled.div`
  flex: 15%;
  display: flex;
  justify-content: flex-end;
  ${Paragraph4};
`

export const StyledLabel = styled.div`
  &,
  ${StyledValue} {
    ${Paragraph3};
    color: #9FB1CB;
    padding-bottom: 23px;
  }
`

export const StyledRow = styled.div`
  padding-bottom: 33px;
  display: flex;
  align-items: center;

  &:last-of-type {
    padding-bottom: 25px;
  }
`

export const StyledCategory = styled.div`
  ${Paragraph5};
  flex: 20%;
  color: ${({ theme }) => theme.colors.mutedprimary };
`

export const StyledProgress = styled.div`
  flex: 65%;
  background-color: ${({ theme }) => theme.colors.gray7 };
  border-radius: ${({ theme }) => theme.radius.xlarge };
  height: 6px;
  overflow: hidden;
`

export const StyledProgressValue = styled.div`
  width: 100%;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.primary };
  border-radius: ${({ theme }) => theme.radius.xlarge };;
`