
import styled from 'styled-components'
import { LabelTypo, Paragraph2 } from './../../global/typography'

export const StyledDetails = styled.div`
  position: relative;
  padding: 30px 30px 5px 30px;
  margin-bottom: 25px;
  background: ${({ theme }) => theme.colors.gray6 };
  border-radius: ${({ theme }) => theme.radius.medium };

  @media ${({ theme }) => theme.bp.md } {
    margin-right: 70px;
  }
`

export const StyledTitle = styled.h2`
  ${LabelTypo};
  color: ${({ theme }) => theme.colors.gray3 };
  margin-bottom: 20px;
`

export const StyledData = styled.h3`
  margin-bottom: 25px;
  width: 50%;
  display: flex;
  flex-direction: column;
  ${Paragraph2};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray3 };

  span {
    ${LabelTypo};
    color: ${({ theme }) => theme.colors.placeholder };
    font-weight: 600;
    margin-bottom: 7px;
  }
  
  input[type='text'] {
    border: 2px solid rgba(0, 0, 0, .1);
    margin-right: 20px;
    font-size: 1.4rem;
    padding: 5px;
  }
`
export const StyledInline = styled.div`
  @media ${({ theme }) => theme.bp.md} {
    display: flex;
  }
`

export const StyledToggle = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: ${({ theme }) => theme.radius.medium };
`