import styled from 'styled-components'
import { Heading5 } from './../../global/typography'

export const StyledBox = styled.section`
  width: 100%;
  margin: 40px 0;

  @media ${({ theme }) => theme.bp.md} {
    width: calc(50% - 30px);
    margin: 30px 30px 0 0;
  }

  @media ${({ theme }) => theme.bp.xmd} {
    width: calc(${({ desktopW }) => desktopW }% - 30px);
  }
`

export const StyledData = styled.div`
  border-radius: 16px;

  @media ${({ theme }) => theme.bp.md} {
    padding: 32px;
    box-shadow: 0px 17px 318px rgba(33, 33, 33, 0.04), 0px 7.64243px 71.0295px rgba(33, 33, 33, 0.0238443), 0px 4.65983px 21.1473px rgba(33, 33, 33, 0.0161557);
  }
`

export const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  
  padding-bottom: 33px;
`

export const StyledTitle = styled.h2`
  display: flex;
  align-items: center;
  ${Heading5};
  color: ${({ theme }) => theme.colors.gray3 };

  img {
    padding-right: 15px;
  }
`

export const StyledMain = styled.div`
  @media ${({ theme }) => theme.bp.md } {
    overflow: hidden;
  }
`