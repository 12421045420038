import React from 'react'

import {
  StyledBreadcrumb,
  StyledBreadcrumbs,
  StyledLink
} from './styled'

export const Breadcrumbs = ({ options, activeStep, backStep }) => {

  const goBack = (step) => {
    if (step < activeStep) {
      backStep()
    }
  }

  return (
    <StyledBreadcrumbs>
      {
        options.length && (
          options.map((item, index) => (
            <StyledBreadcrumb isActive={index === activeStep} key={index}>
              <StyledLink onClick={() => goBack(index)}>
                { item }
              </StyledLink>
            </StyledBreadcrumb>
          ))
        )
      }
    </StyledBreadcrumbs>
  )
}
