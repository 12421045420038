import styled, { css } from 'styled-components'
import { Paragraph2 } from './../../../global/typography'

export const StyledCardActive = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.colors.darkBorder };;
  content: '';
  border-radius: ${({ theme }) => theme.radius.round };
  transition: ${({ theme }) => theme.transitions.fast };

  &:before {
    position: absolute;
    content: '';
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.colors.darkBorder };
    border-radius: ${({ theme }) => theme.radius.round };
    opacity: 0;
  }
`

export const StyledCard = styled.li`
  position: relative;
  display: flex;
  padding: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radius.medium };
  transition: ${({ theme }) => theme.transitions.fast };

  ${({ isActive }) => isActive && css`
    background-color: ${({ theme }) => theme.colors.gray7 };

    ${StyledCardActive} {
      border-color: ${({ theme }) => theme.colors.darkBorder };

      &:before {
        opacity: 1;
      }
    }
  `}
`

export const StyledCardContent = styled.div`
  padding-left: 25px;
  display: flex;
  flex-direction: column;

  span {
    color: ${({ theme }) => theme.colors.gray2 };
  }
`

export const StyledCardBalance = styled.h3`
  ${Paragraph2};
  color: ${({ theme }) => theme.colors.gray3 };
`