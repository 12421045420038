
import styled from 'styled-components'
import { Heading5, Paragraph6, Paragraph2, LabelTypo } from './../../global/typography'

export const StyledVerification = styled.div`
  padding: 32px;
  box-shadow: ${({ theme }) => theme.colors.boxShadow };
  border-radius: ${({ theme }) => theme.radius.medium };
`

export const StyledTitle = styled.h3`
  ${Heading5};
  color: ${({ theme }) => theme.colors.gray3 };
  display: flex;
  place-content: center space-between;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: center;

  img {
    background-color: ${({ theme }) => theme.colors.lightcreme };
    padding: 12px;
    border-radius: ${({ theme }) => theme.radius.medium };
  }
`

export const StyledDescription = styled.p`
  ${Paragraph6};
  color: #627084;
  margin-bottom: 20px;
`

export const StyledCode = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;

  input {
    width: calc(20% - 10px);
    margin-right: 10px;
    background-color: ${({ theme }) => theme.colors.lightcreme };
    border: 0;
    padding: 12px;
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
    border-radius: ${({ theme }) => theme.radius.medium };

    &:last-of-type {
      margin: 0;
    }
  }
`

export const StyledLabel = styled.p`
  color: #627084;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkBorder};
    font-weight: 600;
  }

  img {
    margin-left: 10px;
  }
`

export const StyledRequirements = styled.div`
  margin-top: 35px;
`
