import styled from 'styled-components'

export const StyledGraph = styled.div`
  tspan, svg {
    font-size: 1.2rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray };
    opacity: .7;
  }

  .recharts-default-tooltip {
    background: transparent !important;
    border: 0 !important;
  }
`