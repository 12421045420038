import React from 'react'

import {
  StyledCard,
  StyledCardActive,
  StyledCardContent,
  StyledCardBalance
} from './styled'

export const PickCardSingle = ({ handleClick, isActive, image, balance, number }) => {
  return (
    <StyledCard isActive={isActive} onClick={handleClick}>
      <img src={image} alt={''} />
      <StyledCardContent>
        <StyledCardBalance>
          {balance}
        </StyledCardBalance>
        <span>{number}</span>
      </StyledCardContent>
      <StyledCardActive />
    </StyledCard>
  )
}
