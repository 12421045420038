export const yourCardsData = [
  {
    balance: '36 146.78',
    number: 'xxxx xxxx xxxx 0849',
    expires: '03/22',
    color: '#6065B3'
  },
  {
    balance: '20 546.78',
    number: 'xxxx xxxx xxxx 2222',
    expires: '11/23',
    color: '#D88282'
  },
  {
    balance: '16 546.78',
    number: 'xxxx xxxx xxxx 1113',
    expires: '12/19',
    color: '#91b8ff'
  },
]