import React, { useState } from "react"
import { Head } from './../global/head'
import SEO from "../global/seo"
import { DashboardLayout } from './../layouts/DashboardLayout'
import { DataSection } from './../components/DataSection'
import { WelcomeUser } from './../components/WelcomeUser'
import { PickCard } from './../components/PickCard'
import { SummaryGraph } from './../components/SummaryGraph'
import { TransactionHistory } from './../components/TransactionHistory'
import { ChargesStats } from './../components/ChargesStats'
import { Modal } from './../components/Modal'
import { TransferMoney } from './../components/TransferMoney'

import cards from './../images/icons/mycards.svg'
import grow from './../images/icons/grow.svg'
import history from './../images/icons/history.svg'
import stats from './../images/icons/stats.svg'

const Dashboard = ({ location }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  return (
    (
      <>
        <Head />
        <SEO title="Dashboard" />
        <DashboardLayout pathname={location.pathname}>
          <Modal isOpen={modalOpen} toggleModal={toggleModal}>
            <TransferMoney toggleModal={toggleModal} />
          </Modal>
          <WelcomeUser />
          <DataSection 
            title={'My cards'}
            action={'/'}
            desktopWidth={40}
            icon={cards}
            delay={'0.25'}
          >
            <PickCard toggleModal={toggleModal} />
          </DataSection>
          <DataSection 
            title={'Summary'}
            action={'/'}
            desktopWidth={60}
            icon={grow}
            delay={'0.5'}
          >
            <SummaryGraph/>
          </DataSection>
          <DataSection 
            title={'Transaction summary'}
            action={'/'}
            desktopWidth={60}
            icon={history}
            delay={'0.75'}
          >
            <TransactionHistory/>
          </DataSection>
          <DataSection 
            title={'Charges'}
            action={'/'}
            desktopWidth={40}
            icon={stats}
            delay={'1'}
          >
            <ChargesStats/>
          </DataSection>
        </DashboardLayout>
      </>
    )
  )
}

export default Dashboard
