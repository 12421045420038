import React, { useState, useEffect } from 'react'

import {
  StyledCheckbox,
  StyledCheckboxLabel,
  StyledToggle
} from './styled'

export const Checkbox = ({ label, text, callback }) => {
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    // callback(isChecked)
  }, [isChecked])

  return (
    <StyledCheckbox>
      <StyledCheckboxLabel>
        <span>{label}</span>
        {text}
      </StyledCheckboxLabel>
      <StyledToggle isChecked={isChecked} onClick={() => setChecked(!isChecked) } /> 
    </StyledCheckbox>
  )
}
