import React from 'react'

import {
  StyledRow,
  StyledActivity,
  StyledCategory,
  StyledTime,
  StyledAmount,
} from './../styled'

export const TransactionHistorySingle = ({ activity, category, time, amount }) => {
  return (
    <StyledRow>
      <StyledActivity>
        { activity }
      </StyledActivity>
      <StyledCategory>
        { category }
      </StyledCategory>
      <StyledTime>
        { time }
      </StyledTime>
      <StyledAmount>
        { amount }
      </StyledAmount>
    </StyledRow>
  )
}
